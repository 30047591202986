<template>
    <div class=wrap1200>
        <p class="crumb-tit">
            <span class="s-gray">专家文章</span> / {{info.name}} 
        </p>
        <div class="c-contentBox">
            <p class="tit-dialog">
                {{info.name}}
            </p>
            <div class="info-dialog">
                <span>{{info.resourceFrom}}  |  {{info.publishDate==null ? info.publishDate : '2020-02-20'}}</span>
                <span>阅读  {{info.playTimes}}</span>
                <span>点赞  {{info.voteNum}}</span>
            </div>
            <div v-html="info.content" class="dia-content">
            </div>
        </div>
        <div class="point-show">
            <i class="iconfont icon-zan" @click="addVote"></i>
            <p>{{info.voteNum}}</p>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/dialog.scss';
</style>

<script>
    import {mapGetters} from "vuex";
    export default {
        data () {
            return {
                info: {}
            }
        },
        created () {
            this.loadInfos();
            this.savePlayAction();
        },
        methods: {
            loadInfos() { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/getArticleDetail',
                    params: {
                        themeId: this.$route.query.themeId,
                        id: this.$route.params.id,
                        moduleId: this.$route.query.moduleId,
					}
                }).then(res => {
                    console.log('res', res);
                    this.info = {
                        content: res.data.obj.content ? res.data.obj.content : '暂无内容',
                        publishDate: res.data.obj.publishDate ? res.data.obj.publishDate : 'null',
                        voteNum: res.data.obj.voteNum,
                        playTimes: res.data.obj.playTimes,
                        name: res.data.obj.name,
                        expModuleId: res.data.obj.expModuleId,
                        resourceFrom: res.data.obj.resourceFrom
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            savePlayAction() {
                this.$http({
                    method: 'POST',
                    url: '/api/yyb/v1/expModule/web/savePlayAction',
                    data: {
                        id: this.$route.params.id
					}
                }).then(res => {
                    console.log('res', res);
                }).catch(error => {
                    console.log('error', error)
                })
            },
            addVote() {
                this.$http({
                    method: 'POST',
                    url: '/api/yyb/v1/expModule/web/addVote',
                    data: {
                        themeId: this.info.expModuleId,
                        resourceId: this.$route.params.id,
                        moduleId: this.$route.query.moduleId,
					}
                }).then(res => {
                    console.log('res1', res.data.success);
                    if(res.data.success) {
                        this.$message.success('操作成功.')
                        this.info.voteNum++;
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                    // this.content = res.data.obj.content;
                }).catch(error => {
                    console.log('error', error)
                })
            }
        }
    }
</script>

